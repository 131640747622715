import React from "react"
import "./suppliers.css"

function Suppliers(props) {
  const data = [
    {
      name: "BASF",
      image: "BASF.svg",
      description: "Vitaminas",
    },
    {
      name: "DSM",
      image: "DSM.png",
      description: "Vitaminas",
    },
    {
      name: "Carozzi",
      image: "Carozzi.png",
      description: "Pulpas de frutas",
    },
    {
      name: "PureCircle",
      image: "PureCircle.png",
      description: "Stevia",
    },
    {
      name: "Rousselot",
      image: "Rousselot.png",
      description: "Gelatina, Colágeno",
    },
    {
      name: "PRONEX",
      image: "pronex.png",
      description: "Carmin",
    },
    {
      name: "IFF",
      image: "IFF.png",
      description: "Aromas, Fragancias",
    },
    {
      name: "Char. Hansen",
      image: "ChrHansen.svg",
      description: "Cuajos, Cultivos, Enzimas, Colorantes naturales",
    },
    {
      name: "Agropalma",
      image: "Agropalma.png",
      description:
        "Grasa de palma, Grasas especiales, Grasas para Freír, Grasas para uso industrial",
    },
    {
      name: "Dupont",
      image: "Dupont.png",
      description:
        "Emulsificantes, Antioxidantes, Antimicrobianos, Carbohidratos especiales, Cultivos lácticos, Emulsionantes, Enzimas, Espesantes / Estabilizadores, Fibras, Lecitina, Mejoradores de harina, Proteina de soya",
    },

    {
      name: "Friesland Campina",
      image: "FrieslandCampina.png",
      description:
        "WPC, WPH, WPI, Caseinatos de sodio y calcio, Caseína micelar,Agentes de aireación, Agentes cremosos, Agentes espumantes, Emulsionantes para pasteles, Grasas vegetales en polvo, Cremas no lácteas, Omega 3 ALA / EPA / DHA / LA, Triglicéridos de cadena media - TCM",
    },
    {
      name: "F&F Ingredients",
      image: "F&FIngredients.png",
      description:
        "Jarabe de Glucosa, Jarabe de Maltosa, Jarabe de Fructosa,Maltodextrina, Dextrosa, Almidones Nativos y Modificados de: Trigo, Maíz, Papa, Yuca, Arroz, Grits de Maíz, Sémola de Maíz, Harina de Maíz, Harina de Trigo",
    },
  ]

  return (
    <div>
      <h1 className="suppliersHeader">
        Tenemos relación comercial
        <br />
        con empresas líderes del ramo alimenticio
        <br /> a nivel internacional
      </h1>
      <div className="suppliersContainer">
        {data.map((supplier, index) => (
          <div className="supplier" key={"supplier-elem-" + index}>
            <img
              src={require(`../images/suppliers/${supplier.image}`).default}
              alt={supplier.name}
              placeholder="blurred"
            />

            <p
              dangerouslySetInnerHTML={{
                __html: supplier.description.replace(/,/g, "<br/>"),
              }}
            ></p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Suppliers
