import React from "react"
import "./description.css"

const Description = (props) => (
  <div className="descriptionContainer">
    <div>
      <p>
        Nos enfocamos en la <b>asesoría y consultoría</b> para la industria de
        alimentos.
      </p>
    </div>

    <div>
      <p>
        Contamos con amplia trayectoria en la{" "}
        <b>importación, distribución y venta</b> de productos para empresas del
        ramo alimenticio.
      </p>
    </div>

    <div>
      <p>
        Apoyamos en el desarrollo de productos alimenticios, mejorando{" "}
        <b>procesos de manufactura y soporte regulatorio</b>.
      </p>
    </div>
  </div>
)

export default Description
