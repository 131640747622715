import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Landing from "../components/landing"
import Description from "../components/description"
import Suppliers from "../components/suppliers"

const IndexPage = () => (
  <>
    <Layout>
      <Seo title="BF SUPPLY" />
      <Landing />
      <Description />
      <Suppliers />
    </Layout>
    <div className="extraBanner">
      <p>office@bfsupplypro.com | +58 241-8217446 / 414-5806488</p>
    </div>
  </>
)

export default IndexPage
