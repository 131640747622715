import React from "react"
import "./landing.css"

const Landing = (props) => (
  <div className="landingContainer">
    <div className="landingImage backgroundImage">
      {/* <h1 className="landingTitle">BF SUPPLY PRO</h1> */}
      <img
        className="landingLogo"
        aria-hidden="true"
        alt="BF SUPPLY"
        src={require("../images/bfsupplypro.png").default}
      />
      <div className="landingHeader">
        <div>
          <p>
            office@bfsupplypro.com
            <br />
            +58 241-8217446 / 414-5806488
          </p>
        </div>
      </div>
      {/* <div className="colorLayer"></div> */}
    </div>
  </div>
)

export default Landing
